import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [3];

export const dictionary = {
		"/": [4],
		"/admin": [~9,[3]],
		"/admin/[slug]": [~10,[3]],
		"/admin/[slug]/edit": [~11,[3]],
		"/auth/login": [~12],
		"/[slug]": [~5,[2]],
		"/[slug]/success": [~6,[2]],
		"/[slug]/wishlist": [~7,[2]],
		"/[slug]/wishlist/success": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';